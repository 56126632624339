@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("../../fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #06d758;
}

.tablet {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tablet .promotional-banner {
  background-image: url(../../../static/img/dbanner.svg);
  z-index: 99999;
  width: 744px;
  height: 72px;
  position: absolute;
  background-repeat: no-repeat;
}

.tablet .overlap-wrapper {
  height: 1133px;
  width: 744px;
}

.tablet .overlap {
  height: 1133px;
  position: relative;
  width: 100%;
}

.tablet .illustration {
  width: 413px;
  height: 438px;
  position: absolute;
  top: 695px;
  left: 408px;
}

.tablet .BG-element {
  height: 1133px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 424px;
}

.tablet .nav {
  z-index: 9999;
  width: 680px;
  height: 30px;
  margin-top: 75px;
  position: absolute;
  left: 143px;
}

.tablet .tablet-logo {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
  z-index: 9999;
}

.tablet .how-guide {
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  top: -4px;
  left: 528px;
}

.tablet .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 135px;
  height: 38px;
  position: relative;
}

.tablet .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 11px;
}

.tablet .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 15px;
  left: 103px;
}

.tablet .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 744px;
  height: 137px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 116px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 217px;
  left: 90px;
}

.tablet .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 744px;
  height: 25px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 309px;
  left: 95px;
}

.tablet .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 571px;
  height: 25px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1305px;
  left: 176px;
}

.tablet .back {
  height: 30px;
  left: 624px;
  position: absolute;
  top: 330px;
  transform: rotate(-180deg);
  width: 67px;
}

.tablet .div {
  border: 1px solid;
  border-color: #f7ff7b;
  border-radius: 12px;
  height: 30px;
  position: relative;
  width: 65px;
}

.tablet .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(180deg);
}

.tablet .img {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 38px;
  transform: rotate(180deg);
}

.tablet .step {
  height: 81px;
  left: 102px;
  position: absolute;
  top: 467px;
  width: 686px;
}

.tablet .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 72%;
  height: 58%;
  position: relative;
  top: 200px;
  left: 125px;
}

.tablet .select-enter-your {
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 590px;
  width: 744px;
}

.tablet .buttons {
  height: 54px;
  left: 162px;
  position: absolute;
  top: 628px;
  width: 572px;
}

.tablet .button-field {
  height: 54px;
  left: 296px;
  position: absolute;
  top: 0;
  width: 278px;
}

.tablet .div-wrapper {
  background-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.tablet .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 0;
}

.tablet .enter-field {
  height: 54px;
  left: 0;
  position: absolute;
  top: 0;
  width: 278px;
}

.tablet .overlap-2 {
  border: 3px solid;
  border-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.tablet .text-wrapper-4 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 1px;
}

.tablet .t-cs {
  height: 54px;
  left: 166px;
  position: absolute;
  top: 692px;
  width: 571px;
}

.tablet .overlap-3 {
  height: 54px;
  position: relative;
  width: 569px;
}

.tablet .rectangle {
  border: 1px solid;
  border-color: #152a45;
  border-radius: 100px;
  height: 14px;
  left: 94px;
  position: absolute;
  top: 19px;
  width: 14px;
}

.tablet .i-acknowledge-that-i {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 54px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 569px;
}

.tablet .span {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
}

.tablet .text-wrapper-5 {
  text-decoration: underline;
}

.tablet .email {
  height: 36px;
  left: 260px;
  position: absolute;
  top: 915px;
  width: 181px;
}

.tablet .overlap-4 {
  height: 36px;
  position: relative;
  width: 179px;
  z-index: 9999;
}

.tablet .rectangle-2 {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 14px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.tablet .xionLogo {
  margin-left: 10px;
  max-height: 24px;
}

.tablet .whats-it-about {
  height: 336px;
  position: relative;
  top: 232px;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.tablet .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: -146px;
  left: 192px;
}

/* .p.animate {
  animation: colorFlow 5s linear infinite, scaleUp 1.5s forwards;
  opacity: 1;
} */

.tablet .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 87px;
  left: -7px;
}

.tablet .textabout {
  color: #fff;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;
  align-self: stretch;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 40.4px;
  position: relative;
}

.tablet .textabout-wrapper-6 {
  color: #ffffff;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40.4px;
}

.tablet .spend-it {
  height: 429px;
  position: relative;
  bottom: 167px;
}

.tablet .scan-to-pay-logos {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 584px;
  left: 201px;
}

.tablet .brand-logos {
  height: 80px;
  position: absolute;
  top: 682px;
  left: 104px;
}

.tablet .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 408px;
  left: 238px;
}

.tablet .a6 {
  animation: 3s linear infinite rotate;
  position: absolute;
  top: 3px;
  right: 143px;
  height: 22%;
}

.tablet .apple {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 584px;
  left: 202px;
}

.tablet .google {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 584px;
  left: 202px;
}
