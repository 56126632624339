@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.footer-mobile {
  background-color: #f7ff7b;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  height: 650px;
  padding: 35px 16px 51px 17px;
  display: flex;
  position: relative;
  flex: 1 0 auto; /* Ensure footer can grow and take up the full space */
}

.footer-mobile .overlap-wrapper-new {
  height: 215.31px;
  position: relative;
  width: 326px;
}

.footer-mobile .overlap-new {
  height: 215px;
  position: relative;
}

.footer-mobile .frame-5-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 325px;
  height: 77px;
  display: flex;
  position: absolute;
  left: 18px;
}

.footer-mobile .frame-6-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 31px;
  height: 215px;
  left: 0;
  position: absolute;
  top: 0;
  width: 325px;
}

.footer-mobile .img-new {
  width: 129px;
  height: 29px;
  position: relative;
  left: -51px;
}

.footer-mobile .frame-7-new {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
  width: 262px;
}

.footer-mobile .frame-8-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 157.01px;
}

.footer-mobile .text-wrapper-8-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.footer-mobile .text-wrapper-9-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}

.footer-mobile .frame-9-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 99.05px;
}

/* .footer-mobile  .frame-10-new {
    flex: 0 0 auto;
    position: relative;
    right: -206px;
  } */

.footer-mobile .frame-11-new {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  justify-content: center;
  left: 1066px;
  position: absolute;
  top: 4px;
  width: 381px;
}

.footer-mobile .overlap-group-wrapper-new {
  height: 35.65px;
  position: relative;
  width: 180.77px;
}

.footer-mobile .overlap-group-2-new {
  height: 36px;
  position: relative;
  width: 179px;
}

.footer-mobile .rectangle-2-new {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.footer-mobile .text-wrapper-10-new {
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 179px;
}

.footer-mobile .rectangle-3-new {
  background-color: #ffffff;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.footer-mobile .vector-2-new {
  height: 25px;
  left: 62px;
  position: absolute;
  top: 5px;
  width: 72px;
}

.footer-mobile .frame-12-new {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px 8px;
  width: 326px;
  height: 245px;
  display: flex;
  position: relative;
  bottom: 92px;
}

.footer-mobile .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 209.01px;
  height: 135px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  top: 86px;
  left: 16px;
  text-align: left;
}

.footer-mobile .frame-13-new {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 31px 8px;
  position: relative;
  width: 344px;
}

.footer-mobile .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 88%;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  top: 37px;
  left: 16px;
  text-align: left;
}

.footer-mobile .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 340px;
  display: flex;
  position: relative;
  bottom: -73px;
  left: 3px;
}

.footer-mobile .frame-15-new {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: 340px;
}

.footer-mobile .link-new {
  height: 15px;
  position: relative;
  width: 245.95px;
}

.footer-mobile .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: 0px;
  left: 18px;
}

.footer-mobile .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 151px;
}

.footer-mobile .national-gambling-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 8px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 142px;
}

.footer-mobile .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 179.13px;
}

.footer-mobile .financial-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 8px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 170px;
}

.footer-mobile .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -24px;
}

.footer-mobile .fantasygo-self-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 8px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 147px;
}

.footer-mobile .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  bottom: 290px;
  right: 125px;
}

.footer-mobile .a-new {
  height: 73.14px;
  position: relative;
  width: 485.71px;
}

.footer-mobile .link-style {
  color: #152a45; /* This sets the text color */
  text-decoration: none; /* This removes the underline */
}

.footer-mobile .link-style:hover {
  text-decoration: underline; /* Optional: Adds underline on hover */
}

.footer-mobile .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  display: flex;
  left: 13px;
  position: relative;
}

.footer-mobile .social-icon {
  width: 40px; /* Set width for the icons */
  height: 40px; /* Set height for the icons */
  cursor: pointer;
}

/* Mobile Scratch Card Styles */
.footer-mobile .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 143px;
  left: 66px;
}

.footer-mobile .scratch-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.footer-mobile .scratch-card canvas {
  border-radius: 14px;
  cursor: pointer;
}

.footer-mobile .reveal-container {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #152a45;
}

.footer-mobile .reveal-container p {
  margin: 0;
}

.footer-mobile .reveal-container a {
  color: #152a45;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

/* .footer-mobile p {
  color: #152a45;
  top: -21px;
  position: absolute;
  right: 28px;
} */

/* .footer-mobile .enter-your-email-to {
  background: transparent;
  border: none;
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 179px;
} */

.footer-mobile .element-voucher {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 191px;
  left: 67px;
}

.footer-mobile .rectangle-3 {
  background-color: #ffffff;
  border-radius: 14px;
  width: 179px;
  height: 39px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-mobile .get-your-here {
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: -35px;
}

.footer-mobile .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 11px;
  left: 74px;
}
