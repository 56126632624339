@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("../../fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #06d758;
}

.desktop {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.desktop .promotional-banner {
  background-image: url(../../../static/img/dbanner.svg);
  z-index: 99999;
  width: 1440px;
  height: 72px;
  position: absolute;
  background-repeat: no-repeat;
}

.desktop .overlap-wrapper {
  height: 1024px;
  width: 1440px;
}

.desktop .overlap {
  height: 963px;
  position: relative;
}

.desktop .illustration {
  width: 620px;
  height: 782px;
  position: absolute;
  top: 242px;
  left: 875px;
}

.desktop .BG-element {
  height: 1024px;
  left: 0;
  position: absolute;
  top: 0;
  width: 772px;
}

.desktop .nav {
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

.desktop .desktop-logo {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
  z-index: 9999;
}

@media (max-width: 1250px) {
  .desktop .desktop-logo {
    left: 13%; /* Move the logo to the center horizontally */
  }
}

.desktop .xvoucher-logo {
  margin-left: 265px;
}

.desktop .how-guide {
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  /* top: 15px; */
  left: 1448px;
}

.desktop .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 135px;
  height: 38px;
  position: relative;
}

.desktop .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 9px;
}

.desktop .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 15px;
  left: 103px;
}

.desktop .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1329px;
  left: 476px;
}

.desktop .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1200px;
  height: 172px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 137px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 217px;
  left: 340px;
}

.desktop .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 1200px;
  height: 172px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 309px;
  left: 340px;
}

.desktop .back {
  height: 30px;
  left: 1026px;
  position: absolute;
  top: 330px;
  transform: rotate(-180deg);
  width: 67px;
}

.desktop .div {
  border: 1px solid;
  border-color: #f7ff7b;
  border-radius: 12px;
  height: 30px;
  position: relative;
  width: 65px;
}

.desktop .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(180deg);
}

.desktop .img {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 38px;
  transform: rotate(180deg);
}

.desktop .step {
  height: 235px;
  left: 246px;
  position: absolute;
  top: 388px;
  width: 852px;
}

.desktop .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 72%;
  height: 65%;
  position: relative;
  top: 180px;
  left: 260px;
}

.desktop .select-enter-your {
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 18px;
  font-weight: 400;
  left: 272px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 590px;
  width: 897px;
}

.desktop .buttons {
  height: 54px;
  left: 434px;
  position: absolute;
  top: 628px;
  width: 572px;
}

.desktop .button-field {
  height: 54px;
  left: 296px;
  position: absolute;
  top: 0;
  width: 278px;
}

.desktop .div-wrapper {
  background-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.desktop .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 0;
}

.desktop .enter-field {
  height: 54px;
  left: 0;
  position: absolute;
  top: 0;
  width: 278px;
}

.desktop .overlap-2 {
  border: 3px solid;
  border-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.desktop .text-wrapper-4 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 1px;
}

.desktop .t-cs {
  height: 54px;
  left: 437px;
  position: absolute;
  top: 692px;
  width: 571px;
}

.desktop .overlap-3 {
  height: 54px;
  position: relative;
  width: 569px;
}

.desktop .rectangle {
  border: 1px solid;
  border-color: #152a45;
  border-radius: 100px;
  height: 14px;
  left: 94px;
  position: absolute;
  top: 19px;
  width: 14px;
}

.desktop .i-acknowledge-that-i {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 54px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 569px;
}

.desktop .span {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
}

.desktop .text-wrapper-5 {
  text-decoration: underline;
}

.desktop .email {
  height: 36px;
  left: 531px;
  position: absolute;
  top: 915px;
  width: 181px;
}

.desktop .overlap-4 {
  height: 36px;
  position: relative;
  width: 179px;
  z-index: 9999;
}

.desktop .rectangle-2 {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 14px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

/* .desktop .vectorstep1 {
  background-image: url(../../../static/img/vectorstep1.svg);
  position: absolute;
  top: 403px;
  left: 291px;
  transform: rotate(-16deg);
} */

.desktop .xionLogo {
  margin-left: 10px;
  max-height: 24px;
}

.desktop .footer-new {
  background-color: #f7ff7b;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
  top: 369px;
}

.desktop .overlap-wrapper-new {
  height: 64px;
  margin-right: -0.38px;
  position: relative;
  width: 1478px;
}

.desktop .overlap-new {
  height: 64px;
  position: relative;
  width: 1478px;
}

.desktop .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 87%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .frame-6-new {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 63px;
  position: relative;
}

.desktop .frame-7-new {
  flex: none;
  align-items: center;
  gap: 68px;
  margin-left: 15px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-8-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 157.01px;
}

.desktop .text-wrapper-8-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.desktop .text-wrapper-9-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}

.desktop .frame-9-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 99.05px;
}

.desktop .frame-10-new {
  flex: 0 0 auto;
  position: relative;
  right: -206px;
}

.desktop .frame-11-new {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  justify-content: center;
  left: 1066px;
  position: absolute;
  top: 4px;
  width: 381px;
}

.desktop .overlap-group-wrapper-new {
  height: 35.65px;
  position: relative;
  width: 180.77px;
}

.desktop .overlap-group-2-new {
  height: 36px;
  position: relative;
  width: 179px;
}

.desktop .rectangle-2-new {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.desktop .text-wrapper-10-new {
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 179px;
}

.desktop .rectangle-3-new {
  background-color: #ffffff;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.desktop .vector-2-new {
  height: 25px;
  left: 62px;
  position: absolute;
  top: 5px;
  width: 72px;
}

.desktop .frame-12-new {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 135px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

/* .desktop .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 209.01px;
  height: 135px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 167px;
} */

.desktop .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 81%;
  display: flex;
  position: relative;
}

.desktop .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 46%;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  margin-left: 139px;
}

.desktop .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 1457px;
  display: flex;
  position: relative;
  left: -9px;
}

.desktop .frame-15-new {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: 755.53px;
}

.desktop .link-new {
  height: 15px;
  position: relative;
  width: 245.95px;
}

.desktop .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.desktop .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 151px;
}

.desktop .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.desktop .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 179.13px;
}

.desktop .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.desktop .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.desktop .fantasygo-self-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 9px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 147px;
}

.desktop .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  right: 168px;
}

.desktop .a-new {
  height: 73.14px;
  position: relative;
  width: 485.71px;
}

.desktop .whats-it-about {
  height: 336px;
  position: relative;
  top: 232px;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.desktop .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: -144px;
  left: 622px;
}

.p.animate {
  animation: colorFlow 5s linear infinite, scaleUp 1.5s forwards;
  opacity: 1;
}

.desktop .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 167px;
  left: 0;
}

.desktop .textabout {
  align-self: stretch;
  color: #ffffff;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40.4px;
  position: relative;
  text-align: center;
}

.p.animate,
.textabout.animate {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.p,
.textabout {
  opacity: 0;
  transform: translateY(50px); /* Elements start slightly down and fade in */
}

.desktop .textabout-wrapper-6 {
  color: #ffffff;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40.4px;
}

.desktop .spend-it {
  height: 755px;
  position: relative;
  bottom: 141px;
}

.desktop .scan-to-pay-logos {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 579px;
  left: 647px;
}

.desktop .brand-logos {
  height: 143px;
  position: absolute;
  top: 681px;
  left: 272px;
}

.desktop .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 440px;
  left: 680px;
}

.desktop .a6 {
  position: absolute;
  right: 500px;
  top: 3px;
  animation: rotate 3s linear infinite;
}

.desktop .apple {
  height: 52px;
  position: relative;
  top: 579px;
  left: 647px;
  cursor: pointer;
}

.desktop .google {
  height: 52px;
  position: relative;
  top: 579px;
  left: 647px;
  cursor: pointer;
}

.desktop .link-style {
  color: #152a45; /* This sets the text color */
  text-decoration: none; /* This removes the underline */
}

.desktop .link-style:hover {
  text-decoration: underline; /* Optional: Adds underline on hover */
}
