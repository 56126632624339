@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("../../fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #06d758;
}

.mobile {
  flex-direction: row;
  justify-content: center;
  width: 394px;
  display: flex;
}

/* .mobile .overlap-wrapper {
  width: 393px;
} */

.mobile .promotional-banner {
  background-image: url(../../../static/img/mbanner.svg);
  z-index: 99999;
  width: 392px;
  height: 37px;
  position: absolute;
}

.mobile .overlap {
  height: 852px;
  position: relative;
}

.mobile .BG-element {
  height: 852px;
  left: 0;
  position: absolute;
  top: 0;
  width: 226px;
}

.mobile .illustration {
  height: 211px;
  left: 205px;
  position: absolute;
  top: 641px;
  width: 188px;
}

.mobile .nav {
  z-index: 9999;
  width: 361px;
  height: 30px;
  margin-top: 65px;
  position: absolute;
  /* top: 38px; */
  left: 16px;
}

.mobile .xvoucher-logo {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
}

.mobile .how-guide {
  height: 30px;
  left: 241px;
  position: absolute;
  top: 0;
  width: 122px;
  cursor: pointer;
  z-index: 9999;
}

.mobile .overlap-group {
  background-color: #ffffff;
  border-radius: 12px;
  height: 30px;
  position: relative;
  width: 120px;
}

.mobile .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 3px;
}

.mobile .vector {
  height: 9px;
  left: 92px;
  position: absolute;
  top: 11px;
  width: 19px;
}

.mobile .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 137px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 86px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 168px;
  left: 21px;
}

.mobile .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 373px;
  height: 25px;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 233px;
  left: 8px;
}

.mobile .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 350px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1227px;
  left: 23px;
}

.mobile .back {
  height: 30px;
  left: 312px;
  position: absolute;
  top: 155px;
  transform: rotate(-180deg);
  width: 67px;
}

.mobile .div {
  border: 1px solid;
  border-color: #f7ff7b;
  border-radius: 12px;
  height: 30px;
  position: relative;
  width: 65px;
}

.mobile .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 1px;
  transform: rotate(180deg);
}

.mobile .img {
  height: 9px;
  left: 38px;
  position: absolute;
  top: 9px;
  transform: rotate(180deg);
  width: 19px;
}

.mobile .step {
  height: 53px;
  left: 29px;
  position: absolute;
  top: 322px;
  width: 332px;
}

.mobile .element-darl-wrapper {
  border: 1px dashed;
  border-color: #152a45;
  border-radius: 27.62px;
  height: 55px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 55px;
}

.mobile .element-darl {
  height: 41px;
  left: 6px;
  position: absolute;
  top: 6px;
  width: 41px;
}

.mobile .line-arrow {
  height: 13px;
  left: 207px;
  position: absolute;
  top: 21px;
  width: 74px;
}

.mobile .element-dark-wrapper {
  background-image: url(../../../static/img/2-green-m.png);
  background-size: 100% 100%;
  height: 41px;
  left: 148px;
  position: absolute;
  top: 6px;
  width: 41px;
}

.mobile .element-dark {
  height: 41px;
  left: 0;
  position: absolute;
  top: 0;
  width: 41px;
}

.mobile .line-arrow-2 {
  height: 13px;
  left: 67px;
  position: absolute;
  top: 21px;
  width: 74px;
}

.mobile .img-wrapper {
  background-image: url(../../../static/img/3-green-m.png);
  background-size: 100% 100%;
  height: 41px;
  left: 290px;
  position: absolute;
  top: 6px;
  width: 41px;
}

.mobile .select-enter-your {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 360px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 393px;
  left: 18px;
}

.mobile .buttons {
  height: 54px;
  left: 65px;
  position: absolute;
  top: 478px;
  width: 276px;
}

.mobile .enter-field {
  height: 54px;
  width: 278px;
}

.mobile .div-wrapper {
  border: 3px solid;
  border-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.mobile .text-wrapper-3 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 1px;
}

.mobile .button-field {
  height: 54px;
  left: 65px;
  position: absolute;
  top: 544px;
  width: 278px;
}

.mobile .overlap-2 {
  background-color: #152a45;
  border-radius: 24px;
  height: 54px;
  position: relative;
  width: 276px;
}

.mobile .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 0;
}

.mobile .t-cs {
  height: 54px;
  left: 29px;
  position: absolute;
  top: 611px;
  width: 363px;
}

.mobile .overlap-3 {
  height: 54px;
  position: relative;
  width: 361px;
}

.mobile .rectangle {
  border: 1px solid;
  border-color: #152a45;
  border-radius: 100px;
  height: 14px;
  left: 26px;
  position: absolute;
  top: 20px;
  width: 14px;
}

.mobile .i-acknowledge-that-i {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 8px;
  font-weight: 400;
  height: 54px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 361px;
}

.mobile .span {
  color: #152a45;
  font-family: "Gineso-Regular", Helvetica;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
}

.mobile .text-wrapper-5 {
  text-decoration: underline;
}

.mobile .email {
  height: 36px;
  left: 107px;
  position: absolute;
  top: 718px;
  width: 181px;
}

.mobile .overlap-4 {
  height: 36px;
  position: relative;
  width: 179px;
  z-index: 9999;
}

.mobile .rectangle-2 {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 14px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.mobile .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 0;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: -148px;
  left: 28px;
}

.mobile .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 100%;
  height: 75%;
  position: relative;
  top: 37px;
  left: 0px;
}

.mobile .xionLogo {
  max-height: 14px;
  margin-left: 79px;
}

.mobile .whats-it-about {
  height: 336px;
  position: relative;
  top: 113px;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}

.mobile .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  width: 281px;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: -164px;
  left: 54px;
}

.mobile .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 48px;
  /* left: 3px; */
}

.mobile .textabout {
  color: #fff;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;
  align-self: stretch;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 40.4px;
  position: relative;
}

.mobile .textabout-wrapper-6 {
  color: #ffffff;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 40.4px;
}

.mobile .spend-it {
  height: 863px;
  position: relative;
  bottom: 72px;
}

.mobile .brand-logos {
  height: 359px;
  position: absolute;
  top: 533px;
  left: 28px;
}

.mobile .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 221px;
  left: 24px;
}

.mobile .a6 {
  height: 12%;
  animation: 3s linear infinite rotate;
  position: absolute;
  top: 3px;
  right: 15px;
}

.mobile .scan-to-pay-logos {
  cursor: pointer;
  height: 33px;
  position: relative;
  top: 448px;
  left: 24px;
}

.mobile .apple {
  height: 33px;
  position: relative;
  top: 448px;
  left: 24px;
  cursor: pointer;
}

.mobile .google {
  height: 33px;
  position: relative;
  top: 448px;
  left: 24px;
  cursor: pointer;
}
