@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.footer-tablet {
  background-color: #f7ff7b;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
  top: 240px;
}

.footer-tablet .overlap-wrapper-new {
  height: 64px;
  margin-right: -0.38px;
  position: relative;
  width: 744px;
}

.footer-tablet .overlap-new {
  height: 64px;
  position: relative;
  width: 744px;
}

.footer-tablet .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 744px;
  display: flex;
  position: absolute;
  top: 0;
  left: 40px;
}

.footer-tablet .frame-6-new {
  flex: none;
  align-items: flex-start;
  gap: 31px;
  display: inline-flex;
  position: relative;
}

.footer-tablet .img-new {
  width: 148px;
  height: 39px;
  position: relative;
}

.footer-tablet .frame-7-new {
  flex: none;
  align-items: center;
  gap: 0px;
  display: inline-flex;
  position: relative;
}

.footer-tablet .frame-8-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 157.01px;
}

.footer-tablet .text-wrapper-8-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.footer-tablet .text-wrapper-9-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
}

.footer-tablet .frame-9-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 99.05px;
}

/* .footer-tablet .frame-10-new {
    flex: 0 0 auto;
    position: relative;
    right: -206px;
  } */

.footer-tablet .frame-11-new {
  align-items: flex-start;
  display: flex;
  gap: 12px;
  justify-content: center;
  left: 1066px;
  position: absolute;
  top: 4px;
  width: 381px;
}

.footer-tablet .overlap-group-wrapper-new {
  height: 35.65px;
  position: relative;
  width: 180.77px;
}

.footer-tablet .overlap-group-2-new {
  height: 36px;
  position: relative;
  width: 179px;
}

.footer-tablet .rectangle-2-new {
  border: 2px solid;
  border-color: #152a45;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.footer-tablet .text-wrapper-10-new {
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 179px;
}

.footer-tablet .rectangle-3-new {
  background-color: #ffffff;
  border-radius: 8px;
  height: 35px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 179px;
}

.footer-tablet .vector-2-new {
  height: 25px;
  left: 62px;
  position: absolute;
  top: 5px;
  width: 72px;
}

.footer-tablet .frame-12-new {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 135px;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.footer-tablet .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  letter-spacing: 0;
  height: 135px;
  margin-top: -34px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 42px;
  text-align: left;
}

.footer-tablet .frame-13-new {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  gap: 31px 8px;
  position: relative;
  width: 744px;
}

.footer-tablet .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 78%;
  height: 45px;
  margin-top: 68px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 41px;
  text-align: left;
}

.footer-tablet .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 744px;
  display: flex;
  position: relative;
  left: -75px;
}

.footer-tablet .frame-15-new {
  align-items: flex-start;
  gap: 8px;
  width: 755.53px;
  display: flex;
  position: relative;
  bottom: 15px;
}

.footer-tablet .link-new {
  height: 15px;
  position: relative;
  width: 245.95px;
}

.footer-tablet .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.footer-tablet .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 151px;
}

.footer-tablet .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.footer-tablet .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 179.13px;
}

.footer-tablet .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.footer-tablet .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.footer-tablet .fantasygo-self-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 9px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 147px;
}

.footer-tablet .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  right: 64px;
  bottom: 15px;
}

.footer-tablet .a-new {
  height: 73.14px;
  position: relative;
  width: 485.71px;
}

.footer-tablet .enter-your-email-to {
  background: transparent;
  border: none;
  color: #152a45;
  font-family: "Gineso-NormRegular", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 35px;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  padding: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 179px;
}

.footer-tablet .element-voucher {
  z-index: 99;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 50px;
  left: 525px;
}

.footer-tablet .rectangle-3 {
  background-color: #ffffff;
  border-radius: 14px;
  width: 179px;
  height: 41px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-tablet .get-your-here {
  color: #000000;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: -40px;
}

.footer-tablet .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: 69px;
}
/* Tablet Scratch Card Styles */
.footer-tablet .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  left: 524px;
}

.footer-tablet .scratch-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.footer-tablet .scratch-card canvas {
  border-radius: 14px;
  cursor: pointer;
}

.footer-tablet .reveal-container {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #152a45;
}

.footer-tablet .reveal-container p {
  margin: 0;
}

.footer-tablet .reveal-container a {
  color: #152a45;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.footer-tablet .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  margin-top: 88px;
  display: flex;
  position: absolute;
  right: 389px;
}

.footer-tablet .social-icon {
  width: 40px; /* Set width for the icons */
  height: 40px; /* Set height for the icons */
  cursor: pointer;
}

.footer-tablet .link-style {
  color: #152a45; /* This sets the text color */
  text-decoration: none; /* This removes the underline */
}

.footer-tablet .link-style:hover {
  text-decoration: underline; /* Optional: Adds underline on hover */
}
