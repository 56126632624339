@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("../../../fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #06d758;
}

.how-to-guide-i {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.how-to-guide-i .content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-to-guide-i .nav {
  box-sizing: border-box;
  justify-content: space-between;
  width: 77%;
  margin-top: 100px;
  padding: 20px;
  display: flex;
}

.how-to-guide-i .xvoucher-logo {
  height: 30px;
}

.how-to-guide-i .how-guide {
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  display: flex;
}

.how-to-guide-i .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i .vector {
  height: 9px;
  margin-left: 5px;
}

.how-to-guide-i .content {
  border-radius: 10px;
  width: 100%;
  max-width: 768px;
  margin-top: 20px;
  padding: 20px;
  height: 614px;
  text-align: center; /* Center list items */
}

.how-to-guide-i h1 {
  font-size: 2em;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center; /* Center list items */
}

.how-to-guide-i p,
.how-to-guide-i ol {
  font-size: 1.2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center; /* Center list items */
}

.how-to-guide-i ol {
  list-style-type: decimal;
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i ol li {
  margin-bottom: 20px; /* Add more spacing between each list item */
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  text-align: center; /* Center list items */
}

/* Ensure the list items don't have bullets or numbers */
.how-to-guide-i .steps-list {
  list-style-type: none;
  padding-left: 0;
  text-align: center;
}

.how-to-guide-i .steps-list li {
  margin-bottom: 20px; /* Add some spacing between items */
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  line-height: 1.5;
}
