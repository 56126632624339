.footer-desktop {
  background-color: #f7ff7b;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
}

.footer-desktop .overlap-wrapper-new {
  height: 64px;
  position: relative;
  width: 100%;
}

.footer-desktop .overlap-new {
  height: 64px;
  position: relative;
  width: 100%;
}

.footer-desktop .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 247px;
}

.footer-desktop .frame-6-new {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 63px;
  position: relative;
}

.footer-desktop .img-new {
  height: 39px;
  position: relative;
  right: 59px;
}

.footer-desktop .frame-7-new {
  flex: none;
  align-items: center;
  gap: 68px;
  margin-left: 15px;
  display: inline-flex;
  position: relative;
}

.footer-desktop .frame-8-new,
.frame-9-new {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.footer-desktop .text-wrapper-8-new,
.text-wrapper-9-new {
  align-self: stretch;
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  letter-spacing: 0;
  line-height: 24px;
  white-space: nowrap;
}

.footer-desktop .link-style {
  color: #152a45;
  text-decoration: none;
}

.footer-desktop .link-style:hover {
  text-decoration: underline;
}

.footer-desktop .element-voucher {
  width: 181px;
  height: 36px;
  position: absolute;
  /* top: 1809px; */
  left: 1030px;
}

.footer-desktop .overlap-4 {
  height: 36px;
  position: relative;
  width: 179px;
}

.footer-desktop .rectangle-3 {
  background-color: #ffffff;
  border-radius: 14px;
  height: 41px;
  position: absolute;
  top: 1px;
  width: 179px;
}

.footer-desktop .get-your-here {
  color: #000;
  text-align: center;
  width: 41px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  right: 114px;
}

.footer-desktop .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: 69px;
}

.footer-desktop .frame-12-new {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  /* height: 122px; */
  display: flex;
  position: relative;
  top: 27px;
}

.footer-desktop .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  width: 209.01px;
  height: 135px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 194px;
}

.footer-desktop .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 81%;
  display: flex;
  position: relative;
}

.footer-desktop .DISCLAIMER-new {
  color: #1e1e1e;
  width: 46%;
  height: 45px;
  margin-left: 31px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 167px;
}

.footer-desktop .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 1457px;
  display: flex;
  position: relative;
  left: 77px;
}

.footer-desktop .frame-15-new {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: 755.53px;
}

.footer-desktop .link-new {
  height: 15px;
  position: relative;
  width: 245.95px;
}

.footer-desktop .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.footer-desktop .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 151px;
}

.footer-desktop .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.footer-desktop .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  height: 15px;
  position: relative;
  width: 179.13px;
}

.footer-desktop .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.footer-desktop .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.footer-desktop .fantasygo-self-new {
  color: #1e1e1e;
  font-family: "Titillium Web", Helvetica;
  font-size: 10px;
  font-weight: 400;
  height: 15px;
  left: 9px;
  letter-spacing: 0;
  line-height: 15px;
  position: absolute;
  top: -2px;
  white-space: nowrap;
  width: 147px;
}

.footer-desktop .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  right: 303px;
}

.footer-desktop .a-new {
  height: 73.14px;
  position: relative;
  width: 485.71px;
}

.footer-desktop .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  left: 836px;
  top: 7px;
}

.footer-desktop .scratch-card {
  position: relative;
  width: 100%;
  height: 100%;
}

.footer-desktop .reveal-container {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: #152a45;
}

.footer-desktop .reveal-container p {
  margin: 0;
}

.footer-desktop .reveal-container a {
  color: #152a45;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
}

.footer-desktop .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  margin-top: 88px;
  display: flex;
  position: absolute;
  right: 1109px;
}

.footer-desktop .social-icon {
  width: 40px; /* Set width for the icons */
  height: 40px; /* Set height for the icons */
  cursor: pointer;
}

.footer-desktop .link-style {
  color: #152a45; /* This sets the text color */
  text-decoration: none; /* This removes the underline */
}

.footer-desktop .link-style:hover {
  text-decoration: underline; /* Optional: Adds underline on hover */
}
