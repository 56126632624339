@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("../../../fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  background-color: #06d758;
}

.how-to-guide-m {
  flex-direction: row;
  justify-content: center;
  width: 394px;
  display: flex;
}

.how-to-guide-m .content-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.how-to-guide-m .nav {
  z-index: 9999;
  width: 361px;
  height: 30px;
  margin-top: 65px;
  position: absolute;
  /* top: 38px; */
}

.how-to-guide-m .xvoucher-logo {
  height: 30px;
  left: 0;
  position: absolute;
  top: 0;
  width: 146px;
}

.how-to-guide-m .how-guide {
  height: 30px;
  left: 241px;
  position: absolute;
  top: 0;
  width: 122px;
  cursor: pointer;
  z-index: 9999;
}

.how-to-guide-m .overlap-group {
  background-color: #ffffff;
  border-radius: 12px;
  height: 30px;
  position: relative;
  width: 120px;
}

.how-to-guide-m .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 3px;
}

.how-to-guide-m .vector {
  height: 9px;
  left: 92px;
  position: absolute;
  top: 11px;
  width: 19px;
}

.how-to-guide-m .content {
  border-radius: 10px;
  width: 100%;
  max-width: 768px;
  height: 1340px;
  margin-top: 127px;
  padding: 20px;
  text-align: center;
}

.how-to-guide-m h1 {
  font-size: 2em;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center;
}

.how-to-guide-m p,
.how-to-guide-m ol {
  font-size: 1.2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
  text-align: center;
}

.how-to-guide-m ol {
  list-style-type: decimal;
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m ol li {
  margin-bottom: 20px; /* Add more spacing between each list item */
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m .text-wraps {
  font-size: 1.2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}

/* Ensure the list items don't have bullets or numbers */
.how-to-guide-m .steps-list {
  text-align: center;
  padding-top: 29px;
  list-style-type: none;
}

.how-to-guide-m .steps-list li {
  margin-bottom: 20px; /* Add some spacing between items */
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  line-height: 1.5;
}
