@font-face {
  font-family: "Arlon-SemiBold";
  src: url("../../fonts/Arlon-SemiBold-DEMO.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gineso-NormRegular";
  src: url("../../fonts/Gineso-Norm-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

/* Global reset for box-sizing */
* {
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  margin: 0;
  background-color: #06d758;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* General Terms Styles */
.terms {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  width: 100%; /* Ensure it scales to the screen width */
  max-width: 1200px; /* Set a max width for large screens */
  min-height: 100vh; /* Use min-height for responsive scaling */
}

/* Responsive Nav */
.terms .nav {
  box-sizing: border-box;
  justify-content: space-between;
  width: 82%;
  margin-top: 29px;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

@media (max-width: 768px) {
  .terms .nav {
    padding: 10px;
    width: 90%;
    top: 10px;
  }
}

@media (max-width: 506px) {
  .terms .nav {
    padding: 10px;
    width: 95%;
    top: 10px;
  }
}

/* Logo and Guide button */
.terms .xvoucher-logo {
  height: 30px;
}

.terms .how-guide {
  border-radius: 12px;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 30px;
  position: relative;
}

.terms .text-wrapper {
  color: #06d758;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 5px;
  left: 10px;
}

.terms .vector {
  height: 9px;
  margin-left: 13px;
  position: relative;
  top: 5px;
}

/* Content Section */
.terms .content {
  overflow-wrap: break-word;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 164px;
  padding: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
}

@media (max-width: 768px) {
  .terms .content {
    max-width: 90%;
    margin-top: 100px;
    padding: 15px;
  }
}

@media (max-width: 506px) {
  .terms .content {
    max-width: 95%;
    margin-top: 94px;
    padding: 10px;
  }
}

/* Header and Paragraph Styling */
.terms h1 {
  font-size: 2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .terms h1 {
    font-size: 1.8em; /* Adjust for smaller screens */
  }
}

@media (max-width: 506px) {
  .terms h1 {
    font-size: 1.5em; /* Adjust for even smaller screens */
  }
}

.terms p,
.terms ol {
  font-size: 1.2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  margin-bottom: 20px;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .terms p,
  .terms ol {
    font-size: 1em; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 506px) {
  .terms p,
  .terms ol {
    font-size: 0.9em; /* Further adjustments for mobile screens */
  }
}

.terms ol {
  padding-left: 20px;
  text-align: left;
}

.terms ol li {
  margin-bottom: 15px;
}

/* Footer Section */
.terms .footer {
  margin-top: 30px; /* Adjust this based on the space needed */
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
}

.terms .footer p {
  font-size: 1.2em;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 768px) {
  .terms .footer {
    margin-top: 20px;
    padding: 15px;
    max-width: 90%;
  }
}

@media (max-width: 506px) {
  .terms .footer {
    margin-top: 10px;
    padding: 10px;
    max-width: 95%;
  }
}
