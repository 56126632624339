@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.desktop {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.desktop .promotional-banner {
  z-index: 99999;
  background-image: url("dbanner.6ab5e391.svg");
  background-repeat: no-repeat;
  width: 1440px;
  height: 72px;
  position: absolute;
}

.desktop .overlap-wrapper {
  width: 1440px;
  height: 1024px;
}

.desktop .overlap {
  height: 963px;
  position: relative;
}

.desktop .illustration {
  width: 620px;
  height: 782px;
  position: absolute;
  top: 242px;
  left: 875px;
}

.desktop .BG-element {
  width: 772px;
  height: 1024px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .nav {
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

.desktop .desktop-logo {
  z-index: 9999;
  width: 146px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (width <= 1250px) {
  .desktop .desktop-logo {
    left: 13%;
  }
}

.desktop .xvoucher-logo {
  margin-left: 265px;
}

.desktop .how-guide {
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  left: 1448px;
}

.desktop .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 135px;
  height: 38px;
  position: relative;
}

.desktop .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 9px;
}

.desktop .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 15px;
  left: 103px;
}

.desktop .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 26px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1329px;
  left: 476px;
}

.desktop .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 1200px;
  height: 172px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 137px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 217px;
  left: 340px;
}

.desktop .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 1200px;
  height: 172px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 309px;
  left: 340px;
}

.desktop .back {
  width: 67px;
  height: 30px;
  position: absolute;
  top: 330px;
  left: 1026px;
  transform: rotate(-180deg);
}

.desktop .div {
  border: 1px solid #f7ff7b;
  border-radius: 12px;
  width: 65px;
  height: 30px;
  position: relative;
}

.desktop .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(180deg);
}

.desktop .img {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 38px;
  transform: rotate(180deg);
}

.desktop .step {
  width: 852px;
  height: 235px;
  position: absolute;
  top: 388px;
  left: 246px;
}

.desktop .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 72%;
  height: 65%;
  position: relative;
  top: 180px;
  left: 260px;
}

.desktop .select-enter-your {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 897px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 590px;
  left: 272px;
}

.desktop .buttons {
  width: 572px;
  height: 54px;
  position: absolute;
  top: 628px;
  left: 434px;
}

.desktop .button-field {
  width: 278px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 296px;
}

.desktop .div-wrapper {
  background-color: #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.desktop .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 0;
}

.desktop .enter-field {
  width: 278px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .overlap-2 {
  border: 3px solid #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.desktop .text-wrapper-4 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 1px;
}

.desktop .t-cs {
  width: 571px;
  height: 54px;
  position: absolute;
  top: 692px;
  left: 437px;
}

.desktop .overlap-3 {
  width: 569px;
  height: 54px;
  position: relative;
}

.desktop .rectangle {
  border: 1px solid #152a45;
  border-radius: 100px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 19px;
  left: 94px;
}

.desktop .i-acknowledge-that-i {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 569px;
  height: 54px;
  font-family: Gineso-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .span {
  color: #152a45;
  letter-spacing: 0;
  font-family: Gineso-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.desktop .text-wrapper-5 {
  text-decoration: underline;
}

.desktop .email {
  width: 181px;
  height: 36px;
  position: absolute;
  top: 915px;
  left: 531px;
}

.desktop .overlap-4 {
  z-index: 9999;
  width: 179px;
  height: 36px;
  position: relative;
}

.desktop .rectangle-2 {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.desktop .xionLogo {
  max-height: 24px;
  margin-left: 10px;
}

.desktop .footer-new {
  background-color: #f7ff7b;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
  top: 369px;
}

.desktop .overlap-wrapper-new {
  width: 1478px;
  height: 64px;
  margin-right: -.38px;
  position: relative;
}

.desktop .overlap-new {
  width: 1478px;
  height: 64px;
  position: relative;
}

.desktop .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 87%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .frame-6-new {
  flex: none;
  align-items: flex-start;
  gap: 63px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-7-new {
  flex: none;
  align-items: center;
  gap: 68px;
  margin-left: 15px;
  display: inline-flex;
  position: relative;
}

.desktop .frame-8-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
}

.desktop .text-wrapper-8-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.desktop .text-wrapper-9-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.desktop .frame-9-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
}

.desktop .frame-10-new {
  flex: none;
  position: relative;
  right: -206px;
}

.desktop .frame-11-new {
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 381px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 1066px;
}

.desktop .overlap-group-wrapper-new {
  width: 180.77px;
  height: 35.65px;
  position: relative;
}

.desktop .overlap-group-2-new {
  width: 179px;
  height: 36px;
  position: relative;
}

.desktop .rectangle-2-new {
  border: 2px solid #152a45;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.desktop .text-wrapper-10-new {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.desktop .rectangle-3-new {
  background-color: #fff;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.desktop .vector-2-new {
  width: 72px;
  height: 25px;
  position: absolute;
  top: 5px;
  left: 62px;
}

.desktop .frame-12-new {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.desktop .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 81%;
  display: flex;
  position: relative;
}

.desktop .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  width: 46%;
  height: 45px;
  margin-top: -1px;
  margin-left: 139px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
}

.desktop .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 1457px;
  display: flex;
  position: relative;
  left: -9px;
}

.desktop .frame-15-new {
  align-items: flex-start;
  gap: 8px;
  width: 755.53px;
  display: flex;
  position: relative;
}

.desktop .link-new {
  width: 245.95px;
  height: 15px;
  position: relative;
}

.desktop .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.desktop .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 151px;
  height: 15px;
  position: relative;
}

.desktop .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.desktop .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 179.13px;
  height: 15px;
  position: relative;
}

.desktop .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.desktop .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.desktop .fantasygo-self-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 147px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 9px;
}

.desktop .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  right: 168px;
}

.desktop .a-new {
  width: 485.71px;
  height: 73.14px;
  position: relative;
}

.desktop .whats-it-about {
  height: 336px;
  position: relative;
  top: 232px;
}

@keyframes scaleUp {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(1.2);
  }
}

.desktop .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: -144px;
  left: 622px;
}

.p.animate {
  opacity: 1;
  animation: 5s linear infinite colorFlow, 1.5s forwards scaleUp;
}

.desktop .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 167px;
  left: 0;
}

.desktop .textabout {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  align-self: stretch;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40.4px;
  position: relative;
}

.p.animate, .textabout.animate {
  opacity: 1;
  transition: opacity .5s ease-in-out, transform .5s ease-in-out;
  transform: translateY(0);
}

.p, .textabout {
  opacity: 0;
  transform: translateY(50px);
}

.desktop .textabout-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40.4px;
}

.desktop .spend-it {
  height: 755px;
  position: relative;
  bottom: 141px;
}

.desktop .scan-to-pay-logos {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 579px;
  left: 647px;
}

.desktop .brand-logos {
  height: 143px;
  position: absolute;
  top: 681px;
  left: 272px;
}

.desktop .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 440px;
  left: 680px;
}

.desktop .a6 {
  animation: 3s linear infinite rotate;
  position: absolute;
  top: 3px;
  right: 500px;
}

.desktop .apple, .desktop .google {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 579px;
  left: 647px;
}

.desktop .link-style {
  color: #152a45;
  text-decoration: none;
}

.desktop .link-style:hover {
  text-decoration: underline;
}

.footer-desktop {
  background-color: #f7ff7b;
  flex-direction: column;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
}

.footer-desktop .overlap-wrapper-new, .footer-desktop .overlap-new {
  width: 100%;
  height: 64px;
  position: relative;
}

.footer-desktop .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 247px;
}

.footer-desktop .frame-6-new {
  flex: none;
  align-items: flex-start;
  gap: 63px;
  display: inline-flex;
  position: relative;
}

.footer-desktop .img-new {
  height: 39px;
  position: relative;
  right: 59px;
}

.footer-desktop .frame-7-new {
  flex: none;
  align-items: center;
  gap: 68px;
  margin-left: 15px;
  display: inline-flex;
  position: relative;
}

.footer-desktop .frame-8-new, .frame-9-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  display: flex;
  position: relative;
}

.footer-desktop .text-wrapper-8-new, .text-wrapper-9-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.footer-desktop .element-voucher {
  width: 181px;
  height: 36px;
  position: absolute;
  left: 1030px;
}

.footer-desktop .overlap-4 {
  width: 179px;
  height: 36px;
  position: relative;
}

.footer-desktop .rectangle-3 {
  background-color: #fff;
  border-radius: 14px;
  width: 179px;
  height: 41px;
  position: absolute;
  top: 1px;
}

.footer-desktop .get-your-here {
  color: #000;
  text-align: center;
  width: 41px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  right: 114px;
}

.footer-desktop .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: 69px;
}

.footer-desktop .frame-12-new {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  display: flex;
  position: relative;
  top: 27px;
}

.footer-desktop .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  width: 209.01px;
  height: 135px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 194px;
}

.footer-desktop .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 81%;
  display: flex;
  position: relative;
}

.footer-desktop .DISCLAIMER-new {
  color: #1e1e1e;
  width: 46%;
  height: 45px;
  margin-left: 31px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 167px;
}

.footer-desktop .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 1457px;
  display: flex;
  position: relative;
  left: 77px;
}

.footer-desktop .frame-15-new {
  align-items: flex-start;
  gap: 8px;
  width: 755.53px;
  display: flex;
  position: relative;
}

.footer-desktop .link-new {
  width: 245.95px;
  height: 15px;
  position: relative;
}

.footer-desktop .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.footer-desktop .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 151px;
  height: 15px;
  position: relative;
}

.footer-desktop .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.footer-desktop .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 179.13px;
  height: 15px;
  position: relative;
}

.footer-desktop .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.footer-desktop .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.footer-desktop .fantasygo-self-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 147px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 9px;
}

.footer-desktop .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  right: 303px;
}

.footer-desktop .a-new {
  width: 485.71px;
  height: 73.14px;
  position: relative;
}

.footer-desktop .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 7px;
  left: 836px;
}

.footer-desktop .scratch-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.footer-desktop .reveal-container {
  color: #152a45;
  border: 2px solid #152a45;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.footer-desktop .reveal-container p {
  margin: 0;
}

.footer-desktop .reveal-container a {
  color: #152a45;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.footer-desktop .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  margin-top: 88px;
  display: flex;
  position: absolute;
  right: 1109px;
}

.footer-desktop .social-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.footer-desktop .link-style {
  color: #152a45;
  text-decoration: none;
}

.footer-desktop .link-style:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.tablet {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.tablet .promotional-banner {
  z-index: 99999;
  background-image: url("dbanner.6ab5e391.svg");
  background-repeat: no-repeat;
  width: 744px;
  height: 72px;
  position: absolute;
}

.tablet .overlap-wrapper {
  width: 744px;
  height: 1133px;
}

.tablet .overlap {
  width: 100%;
  height: 1133px;
  position: relative;
}

.tablet .illustration {
  width: 413px;
  height: 438px;
  position: absolute;
  top: 695px;
  left: 408px;
}

.tablet .BG-element {
  width: 424px;
  height: 1133px;
  position: absolute;
  top: 0;
  left: 76px;
}

.tablet .nav {
  z-index: 9999;
  width: 680px;
  height: 30px;
  margin-top: 75px;
  position: absolute;
  left: 143px;
}

.tablet .tablet-logo {
  z-index: 9999;
  width: 146px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.tablet .how-guide {
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  top: -4px;
  left: 528px;
}

.tablet .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 135px;
  height: 38px;
  position: relative;
}

.tablet .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 6px;
  left: 11px;
}

.tablet .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 15px;
  left: 103px;
}

.tablet .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 744px;
  height: 137px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 116px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 217px;
  left: 90px;
}

.tablet .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 744px;
  height: 25px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 309px;
  left: 95px;
}

.tablet .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 571px;
  height: 25px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1305px;
  left: 176px;
}

.tablet .back {
  width: 67px;
  height: 30px;
  position: absolute;
  top: 330px;
  left: 624px;
  transform: rotate(-180deg);
}

.tablet .div {
  border: 1px solid #f7ff7b;
  border-radius: 12px;
  width: 65px;
  height: 30px;
  position: relative;
}

.tablet .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -4px;
  left: 1px;
  transform: rotate(180deg);
}

.tablet .img {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 10px;
  left: 38px;
  transform: rotate(180deg);
}

.tablet .step {
  width: 686px;
  height: 81px;
  position: absolute;
  top: 467px;
  left: 102px;
}

.tablet .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 72%;
  height: 58%;
  position: relative;
  top: 200px;
  left: 125px;
}

.tablet .select-enter-your {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 744px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 590px;
  left: 0;
}

.tablet .buttons {
  width: 572px;
  height: 54px;
  position: absolute;
  top: 628px;
  left: 162px;
}

.tablet .button-field {
  width: 278px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 296px;
}

.tablet .div-wrapper {
  background-color: #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.tablet .text-wrapper-3 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 0;
}

.tablet .enter-field {
  width: 278px;
  height: 54px;
  position: absolute;
  top: 0;
  left: 0;
}

.tablet .overlap-2 {
  border: 3px solid #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.tablet .text-wrapper-4 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 12px;
  left: 1px;
}

.tablet .t-cs {
  width: 571px;
  height: 54px;
  position: absolute;
  top: 692px;
  left: 166px;
}

.tablet .overlap-3 {
  width: 569px;
  height: 54px;
  position: relative;
}

.tablet .rectangle {
  border: 1px solid #152a45;
  border-radius: 100px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 19px;
  left: 94px;
}

.tablet .i-acknowledge-that-i {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 569px;
  height: 54px;
  font-family: Gineso-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.tablet .span {
  color: #152a45;
  letter-spacing: 0;
  font-family: Gineso-Regular, Helvetica;
  font-size: 10px;
  font-weight: 400;
}

.tablet .text-wrapper-5 {
  text-decoration: underline;
}

.tablet .email {
  width: 181px;
  height: 36px;
  position: absolute;
  top: 915px;
  left: 260px;
}

.tablet .overlap-4 {
  z-index: 9999;
  width: 179px;
  height: 36px;
  position: relative;
}

.tablet .rectangle-2 {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.tablet .xionLogo {
  max-height: 24px;
  margin-left: 10px;
}

.tablet .whats-it-about {
  height: 336px;
  position: relative;
  top: 232px;
}

.tablet .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: -146px;
  left: 192px;
}

.tablet .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 87px;
  left: -7px;
}

.tablet .textabout {
  color: #fff;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;
  align-self: stretch;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 40.4px;
  position: relative;
}

.tablet .textabout-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40.4px;
}

.tablet .spend-it {
  height: 429px;
  position: relative;
  bottom: 167px;
}

.tablet .scan-to-pay-logos {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 584px;
  left: 201px;
}

.tablet .brand-logos {
  height: 80px;
  position: absolute;
  top: 682px;
  left: 104px;
}

.tablet .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 408px;
  left: 238px;
}

.tablet .a6 {
  height: 22%;
  animation: 3s linear infinite rotate;
  position: absolute;
  top: 3px;
  right: 143px;
}

.tablet .apple, .tablet .google {
  cursor: pointer;
  height: 52px;
  position: relative;
  top: 584px;
  left: 202px;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.footer-tablet {
  background-color: #f7ff7b;
  flex-direction: column;
  flex: none;
  align-self: stretch;
  align-items: flex-start;
  gap: 71px;
  width: 100%;
  padding: 51px 104px;
  display: flex;
  position: relative;
  top: 240px;
}

.footer-tablet .overlap-wrapper-new {
  width: 744px;
  height: 64px;
  margin-right: -.38px;
  position: relative;
}

.footer-tablet .overlap-new {
  width: 744px;
  height: 64px;
  position: relative;
}

.footer-tablet .frame-5-new {
  justify-content: space-between;
  align-items: flex-start;
  width: 744px;
  display: flex;
  position: absolute;
  top: 0;
  left: 40px;
}

.footer-tablet .frame-6-new {
  flex: none;
  align-items: flex-start;
  gap: 31px;
  display: inline-flex;
  position: relative;
}

.footer-tablet .img-new {
  width: 148px;
  height: 39px;
  position: relative;
}

.footer-tablet .frame-7-new {
  flex: none;
  align-items: center;
  gap: 0;
  display: inline-flex;
  position: relative;
}

.footer-tablet .frame-8-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
}

.footer-tablet .text-wrapper-8-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.footer-tablet .text-wrapper-9-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.footer-tablet .frame-9-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
}

.footer-tablet .frame-11-new {
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 381px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 1066px;
}

.footer-tablet .overlap-group-wrapper-new {
  width: 180.77px;
  height: 35.65px;
  position: relative;
}

.footer-tablet .overlap-group-2-new {
  width: 179px;
  height: 36px;
  position: relative;
}

.footer-tablet .rectangle-2-new {
  border: 2px solid #152a45;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-tablet .text-wrapper-10-new {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-tablet .rectangle-3-new {
  background-color: #fff;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-tablet .vector-2-new {
  width: 72px;
  height: 25px;
  position: absolute;
  top: 5px;
  left: 62px;
}

.footer-tablet .frame-12-new {
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
  width: 100%;
  height: 135px;
  display: flex;
  position: relative;
}

.footer-tablet .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  letter-spacing: 0;
  text-align: left;
  height: 135px;
  margin-top: -34px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  left: 42px;
}

.footer-tablet .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 744px;
  display: flex;
  position: relative;
}

.footer-tablet .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  text-align: left;
  width: 78%;
  height: 45px;
  margin-top: 68px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  left: 41px;
}

.footer-tablet .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 744px;
  display: flex;
  position: relative;
  left: -75px;
}

.footer-tablet .frame-15-new {
  align-items: flex-start;
  gap: 8px;
  width: 755.53px;
  display: flex;
  position: relative;
  bottom: 15px;
}

.footer-tablet .link-new {
  width: 245.95px;
  height: 15px;
  position: relative;
}

.footer-tablet .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 116px;
}

.footer-tablet .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 151px;
  height: 15px;
  position: relative;
}

.footer-tablet .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -1px;
  left: 28px;
}

.footer-tablet .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 179.13px;
  height: 15px;
  position: relative;
}

.footer-tablet .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 28px;
}

.footer-tablet .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -69px;
}

.footer-tablet .fantasygo-self-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 147px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 9px;
}

.footer-tablet .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  bottom: 15px;
  right: 64px;
}

.footer-tablet .a-new {
  width: 485.71px;
  height: 73.14px;
  position: relative;
}

.footer-tablet .enter-your-email-to {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  background: none;
  border: none;
  width: 179px;
  height: 35px;
  padding: 0;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-tablet .element-voucher {
  z-index: 99;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 50px;
  left: 525px;
}

.footer-tablet .rectangle-3 {
  background-color: #fff;
  border-radius: 14px;
  width: 179px;
  height: 41px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-tablet .get-your-here {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: -40px;
}

.footer-tablet .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 12px;
  left: 69px;
}

.footer-tablet .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  left: 524px;
}

.footer-tablet .scratch-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.footer-tablet .scratch-card canvas {
  cursor: pointer;
  border-radius: 14px;
}

.footer-tablet .reveal-container {
  color: #152a45;
  border: 2px solid #152a45;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.footer-tablet .reveal-container p {
  margin: 0;
}

.footer-tablet .reveal-container a {
  color: #152a45;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.footer-tablet .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  margin-top: 88px;
  display: flex;
  position: absolute;
  right: 389px;
}

.footer-tablet .social-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.footer-tablet .link-style {
  color: #152a45;
  text-decoration: none;
}

.footer-tablet .link-style:hover {
  text-decoration: underline;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.mobile {
  flex-direction: row;
  justify-content: center;
  width: 394px;
  display: flex;
}

.mobile .promotional-banner {
  z-index: 99999;
  background-image: url("mbanner.cf847624.svg");
  width: 392px;
  height: 37px;
  position: absolute;
}

.mobile .overlap {
  height: 852px;
  position: relative;
}

.mobile .BG-element {
  width: 226px;
  height: 852px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .illustration {
  width: 188px;
  height: 211px;
  position: absolute;
  top: 641px;
  left: 205px;
}

.mobile .nav {
  z-index: 9999;
  width: 361px;
  height: 30px;
  margin-top: 65px;
  position: absolute;
  left: 16px;
}

.mobile .xvoucher-logo {
  width: 146px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .how-guide {
  cursor: pointer;
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 241px;
}

.mobile .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 30px;
  position: relative;
}

.mobile .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 3px;
}

.mobile .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 11px;
  left: 92px;
}

.mobile .header-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 340px;
  height: 137px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 86px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 168px;
  left: 21px;
}

.mobile .header-subcopy {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 373px;
  height: 25px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 233px;
  left: 8px;
}

.mobile .spend-it-copy {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 350px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 25px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 1227px;
  left: 23px;
}

.mobile .back {
  width: 67px;
  height: 30px;
  position: absolute;
  top: 155px;
  left: 312px;
  transform: rotate(-180deg);
}

.mobile .div {
  border: 1px solid #f7ff7b;
  border-radius: 12px;
  width: 65px;
  height: 30px;
  position: relative;
}

.mobile .text-wrapper-2 {
  color: #f7ff7b;
  letter-spacing: 0;
  text-align: center;
  width: 37px;
  height: 27px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: -5px;
  left: 1px;
  transform: rotate(180deg);
}

.mobile .img {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 9px;
  left: 38px;
  transform: rotate(180deg);
}

.mobile .step {
  width: 332px;
  height: 53px;
  position: absolute;
  top: 322px;
  left: 29px;
}

.mobile .element-darl-wrapper {
  border: 1px dashed #152a45;
  border-radius: 27.62px;
  width: 55px;
  height: 55px;
  position: absolute;
  top: -1px;
  left: -1px;
}

.mobile .element-darl {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 6px;
}

.mobile .line-arrow {
  width: 74px;
  height: 13px;
  position: absolute;
  top: 21px;
  left: 207px;
}

.mobile .element-dark-wrapper {
  background-image: url("2-green-m.2f9ac652.png");
  background-size: 100% 100%;
  width: 41px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 148px;
}

.mobile .element-dark {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .line-arrow-2 {
  width: 74px;
  height: 13px;
  position: absolute;
  top: 21px;
  left: 67px;
}

.mobile .img-wrapper {
  background-image: url("3-green-m.2eaa6108.png");
  background-size: 100% 100%;
  width: 41px;
  height: 41px;
  position: absolute;
  top: 6px;
  left: 290px;
}

.mobile .select-enter-your {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 360px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 393px;
  left: 18px;
}

.mobile .buttons {
  width: 276px;
  height: 54px;
  position: absolute;
  top: 478px;
  left: 65px;
}

.mobile .enter-field {
  width: 278px;
  height: 54px;
}

.mobile .div-wrapper {
  border: 3px solid #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.mobile .text-wrapper-3 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 273px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 1px;
}

.mobile .button-field {
  width: 278px;
  height: 54px;
  position: absolute;
  top: 544px;
  left: 65px;
}

.mobile .overlap-2 {
  background-color: #152a45;
  border-radius: 24px;
  width: 276px;
  height: 54px;
  position: relative;
}

.mobile .text-wrapper-4 {
  color: #fff;
  letter-spacing: 0;
  text-align: center;
  width: 276px;
  height: 54px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 15px;
  left: 0;
}

.mobile .t-cs {
  width: 363px;
  height: 54px;
  position: absolute;
  top: 611px;
  left: 29px;
}

.mobile .overlap-3 {
  width: 361px;
  height: 54px;
  position: relative;
}

.mobile .rectangle {
  border: 1px solid #152a45;
  border-radius: 100px;
  width: 14px;
  height: 14px;
  position: absolute;
  top: 20px;
  left: 26px;
}

.mobile .i-acknowledge-that-i {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 361px;
  height: 54px;
  font-family: Gineso-Regular, Helvetica;
  font-size: 8px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.mobile .span {
  color: #152a45;
  letter-spacing: 0;
  font-family: Gineso-Regular, Helvetica;
  font-size: 8px;
  font-weight: 400;
}

.mobile .text-wrapper-5 {
  text-decoration: underline;
}

.mobile .email {
  width: 181px;
  height: 36px;
  position: absolute;
  top: 718px;
  left: 107px;
}

.mobile .overlap-4 {
  z-index: 9999;
  width: 179px;
  height: 36px;
  position: relative;
}

.mobile .rectangle-2 {
  border: 2px solid #152a45;
  border-radius: 14px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.mobile .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 0;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  transition: all .5s ease-in-out;
  position: absolute;
  top: -148px;
  left: 28px;
}

.mobile .step-iframe {
  z-index: 1000;
  background: none;
  border: none;
  width: 100%;
  height: 75%;
  position: relative;
  top: 37px;
  left: 0;
}

.mobile .xionLogo {
  max-height: 14px;
  margin-left: 79px;
}

.mobile .whats-it-about {
  height: 336px;
  position: relative;
  top: 113px;
}

.mobile .p {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  opacity: 1;
  width: 281px;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 38px;
  font-weight: 400;
  line-height: 36px;
  transition: all .5s ease-in-out;
  position: absolute;
  top: -164px;
  left: 54px;
}

.mobile .flexcontainer {
  flex-direction: column;
  align-items: flex-start;
  gap: 17px;
  height: 239px;
  display: flex;
  position: relative;
  top: 48px;
}

.mobile .textabout {
  color: #fff;
  letter-spacing: 0;
  opacity: 1;
  text-align: center;
  align-self: stretch;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 24px;
  font-weight: 400;
  line-height: 40.4px;
  position: relative;
}

.mobile .textabout-wrapper-6 {
  color: #fff;
  letter-spacing: 0;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 30px;
  font-weight: 400;
  line-height: 40.4px;
}

.mobile .spend-it {
  height: 863px;
  position: relative;
  bottom: 72px;
}

.mobile .brand-logos {
  height: 359px;
  position: absolute;
  top: 533px;
  left: 28px;
}

.mobile .textabout-wrapper-7 {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  height: 36px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 42px;
  font-weight: 400;
  line-height: 36px;
  position: absolute;
  top: 221px;
  left: 24px;
}

.mobile .a6 {
  height: 12%;
  animation: 3s linear infinite rotate;
  position: absolute;
  top: 3px;
  right: 15px;
}

.mobile .scan-to-pay-logos, .mobile .apple, .mobile .google {
  cursor: pointer;
  height: 33px;
  position: relative;
  top: 448px;
  left: 24px;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.footer-mobile {
  background-color: #f7ff7b;
  flex-direction: column;
  flex: 1 0 auto;
  align-self: stretch;
  align-items: flex-start;
  gap: 36px;
  width: 100%;
  height: 650px;
  padding: 35px 16px 51px 17px;
  display: flex;
  position: relative;
}

.footer-mobile .overlap-wrapper-new {
  width: 326px;
  height: 215.31px;
  position: relative;
}

.footer-mobile .overlap-new {
  height: 215px;
  position: relative;
}

.footer-mobile .frame-5-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 325px;
  height: 77px;
  display: flex;
  position: absolute;
  left: 18px;
}

.footer-mobile .frame-6-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  width: 325px;
  height: 215px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-mobile .img-new {
  width: 129px;
  height: 29px;
  position: relative;
  left: -51px;
}

.footer-mobile .frame-7-new {
  flex: none;
  align-items: center;
  gap: 16px;
  width: 262px;
  display: flex;
  position: relative;
}

.footer-mobile .frame-8-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 157.01px;
  display: flex;
  position: relative;
}

.footer-mobile .text-wrapper-8-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.footer-mobile .text-wrapper-9-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  align-self: stretch;
  height: 24px;
  font-family: Titillium Web, Helvetica;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  position: relative;
}

.footer-mobile .frame-9-new {
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 99.05px;
  display: flex;
  position: relative;
}

.footer-mobile .frame-11-new {
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  width: 381px;
  display: flex;
  position: absolute;
  top: 4px;
  left: 1066px;
}

.footer-mobile .overlap-group-wrapper-new {
  width: 180.77px;
  height: 35.65px;
  position: relative;
}

.footer-mobile .overlap-group-2-new {
  width: 179px;
  height: 36px;
  position: relative;
}

.footer-mobile .rectangle-2-new {
  border: 2px solid #152a45;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-mobile .text-wrapper-10-new {
  color: #152a45;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-mobile .rectangle-3-new {
  background-color: #fff;
  border-radius: 8px;
  width: 179px;
  height: 35px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-mobile .vector-2-new {
  width: 72px;
  height: 25px;
  position: absolute;
  top: 5px;
  left: 62px;
}

.footer-mobile .frame-12-new {
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px 8px;
  width: 326px;
  height: 245px;
  display: flex;
  position: relative;
  bottom: 92px;
}

.footer-mobile .fantasy-go-pty-ltd-new {
  color: #1e1e1e;
  letter-spacing: 0;
  text-align: left;
  width: 209.01px;
  height: 135px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  top: 86px;
  left: 16px;
}

.footer-mobile .frame-13-new {
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 31px 8px;
  width: 344px;
  display: flex;
  position: relative;
}

.footer-mobile .DISCLAIMER-new {
  color: #1e1e1e;
  letter-spacing: 0;
  text-align: left;
  width: 88%;
  height: 45px;
  margin-top: -1px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: relative;
  top: 37px;
  left: 16px;
}

.footer-mobile .frame-14-new {
  justify-content: space-between;
  align-items: flex-end;
  width: 340px;
  display: flex;
  position: relative;
  bottom: -73px;
  left: 3px;
}

.footer-mobile .frame-15-new {
  align-items: flex-start;
  gap: 8px;
  width: 340px;
  display: flex;
  position: relative;
}

.footer-mobile .link-new {
  width: 245.95px;
  height: 15px;
  position: relative;
}

.footer-mobile .national-responsible-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 246px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: 0;
  left: 18px;
}

.footer-mobile .national-gambling-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 151px;
  height: 15px;
  position: relative;
}

.footer-mobile .national-gambling-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 142px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 8px;
}

.footer-mobile .financial-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 179.13px;
  height: 15px;
  position: relative;
}

.footer-mobile .financial-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 170px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 8px;
}

.footer-mobile .fantasygo-self-wrapper-new {
  border-color: #1e1e1e;
  border-left-style: solid;
  border-left-width: 1px;
  width: 155.45px;
  height: 15px;
  position: relative;
  left: -24px;
}

.footer-mobile .fantasygo-self-new {
  color: #1e1e1e;
  letter-spacing: 0;
  white-space: nowrap;
  width: 147px;
  height: 15px;
  font-family: Titillium Web, Helvetica;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
  position: absolute;
  top: -2px;
  left: 8px;
}

.footer-mobile .group-2-new {
  width: 93.4px;
  height: 23.48px;
  position: relative;
  bottom: 290px;
  right: 125px;
}

.footer-mobile .a-new {
  width: 485.71px;
  height: 73.14px;
  position: relative;
}

.footer-mobile .link-style {
  color: #152a45;
  text-decoration: none;
}

.footer-mobile .link-style:hover {
  text-decoration: underline;
}

.footer-mobile .social-icons {
  z-index: 9999;
  justify-content: space-between;
  gap: 15px;
  display: flex;
  position: relative;
  left: 13px;
}

.footer-mobile .social-icon {
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.footer-mobile .email-collector {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 143px;
  left: 66px;
}

.footer-mobile .scratch-card {
  width: 100%;
  height: 100%;
  position: relative;
}

.footer-mobile .scratch-card canvas {
  cursor: pointer;
  border-radius: 14px;
}

.footer-mobile .reveal-container {
  color: #152a45;
  border: 2px solid #152a45;
  border-radius: 14px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

.footer-mobile .reveal-container p {
  margin: 0;
}

.footer-mobile .reveal-container a {
  color: #152a45;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
}

.footer-mobile .element-voucher {
  z-index: 9999;
  width: 181px;
  height: 36px;
  position: absolute;
  top: 191px;
  left: 67px;
}

.footer-mobile .rectangle-3 {
  background-color: #fff;
  border-radius: 14px;
  width: 179px;
  height: 39px;
  position: absolute;
  top: 1px;
  left: 0;
}

.footer-mobile .get-your-here {
  color: #000;
  letter-spacing: 0;
  text-align: center;
  width: 179px;
  height: 35px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 10px;
  left: -35px;
}

.footer-mobile .clip-path-group {
  width: 67px;
  height: 18px;
  position: absolute;
  top: 11px;
  left: 74px;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.how-to-guide-d {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.how-to-guide-d .content-wrapper {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.how-to-guide-d .nav {
  z-index: 9999;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

.how-to-guide-d .xvoucher-logo {
  height: 30px;
}

.how-to-guide-d .how-guide {
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  display: flex;
}

.how-to-guide-d .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-d .vector {
  height: 9px;
  margin-left: 5px;
}

.how-to-guide-d .content {
  text-align: center;
  border-radius: 10px;
  max-width: 1350px;
  height: 620px;
  margin-top: 161px;
  padding: 20px;
}

.how-to-guide-d h1 {
  margin-bottom: 20px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-d p, .how-to-guide-d ol {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-d ol {
  text-align: left;
  padding-left: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  list-style-type: decimal;
}

.how-to-guide-d ol li {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-d .steps-list {
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}

.how-to-guide-d .steps-list li {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  line-height: 1.5;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.how-to-guide-i {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.how-to-guide-i .content-wrapper {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.how-to-guide-i .nav {
  box-sizing: border-box;
  justify-content: space-between;
  width: 77%;
  margin-top: 100px;
  padding: 20px;
  display: flex;
}

.how-to-guide-i .xvoucher-logo {
  height: 30px;
}

.how-to-guide-i .how-guide {
  background-color: #fff;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 8px 10px;
  display: flex;
}

.how-to-guide-i .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i .vector {
  height: 9px;
  margin-left: 5px;
}

.how-to-guide-i .content {
  text-align: center;
  border-radius: 10px;
  width: 100%;
  max-width: 768px;
  height: 614px;
  margin-top: 20px;
  padding: 20px;
}

.how-to-guide-i h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i p, .how-to-guide-i ol {
  text-align: center;
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i ol {
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  list-style-type: decimal;
}

.how-to-guide-i ol li {
  text-align: center;
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-i .steps-list {
  text-align: center;
  padding-left: 0;
  list-style-type: none;
}

.how-to-guide-i .steps-list li {
  text-align: center;
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  line-height: 1.5;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

body {
  background-color: #06d758;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow-x: hidden;
}

.how-to-guide-m {
  flex-direction: row;
  justify-content: center;
  width: 394px;
  display: flex;
}

.how-to-guide-m .content-wrapper {
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  display: flex;
}

.how-to-guide-m .nav {
  z-index: 9999;
  width: 361px;
  height: 30px;
  margin-top: 65px;
  position: absolute;
}

.how-to-guide-m .xvoucher-logo {
  width: 146px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 0;
}

.how-to-guide-m .how-guide {
  cursor: pointer;
  z-index: 9999;
  width: 122px;
  height: 30px;
  position: absolute;
  top: 0;
  left: 241px;
}

.how-to-guide-m .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 30px;
  position: relative;
}

.how-to-guide-m .text-wrapper {
  color: #06d758;
  letter-spacing: 0;
  text-align: center;
  width: 92px;
  height: 30px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: absolute;
  top: 5px;
  left: 3px;
}

.how-to-guide-m .vector {
  width: 19px;
  height: 9px;
  position: absolute;
  top: 11px;
  left: 92px;
}

.how-to-guide-m .content {
  text-align: center;
  border-radius: 10px;
  width: 100%;
  max-width: 768px;
  height: 1340px;
  margin-top: 127px;
  padding: 20px;
}

.how-to-guide-m h1 {
  text-align: center;
  margin-bottom: 20px;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m p, .how-to-guide-m ol {
  text-align: center;
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m ol {
  text-align: center;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
  list-style-type: decimal;
}

.how-to-guide-m ol li {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m .text-wraps {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

.how-to-guide-m .steps-list {
  text-align: center;
  padding-top: 29px;
  list-style-type: none;
}

.how-to-guide-m .steps-list li {
  text-align: center;
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  line-height: 1.5;
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.terms {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  padding: 20px;
  display: flex;
}

.terms .nav {
  box-sizing: border-box;
  justify-content: space-between;
  width: 82%;
  margin-top: 29px;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

@media (width <= 768px) {
  .terms .nav {
    width: 90%;
    padding: 10px;
    top: 10px;
  }
}

@media (width <= 506px) {
  .terms .nav {
    width: 95%;
    padding: 10px;
    top: 10px;
  }
}

.terms .xvoucher-logo {
  height: 30px;
}

.terms .how-guide {
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.terms .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 30px;
  position: relative;
}

.terms .text-wrapper {
  color: #06d758;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 5px;
  left: 10px;
}

.terms .vector {
  height: 9px;
  margin-left: 13px;
  position: relative;
  top: 5px;
}

.terms .content {
  overflow-wrap: break-word;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 164px;
  padding: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
}

@media (width <= 768px) {
  .terms .content {
    max-width: 90%;
    margin-top: 100px;
    padding: 15px;
  }
}

@media (width <= 506px) {
  .terms .content {
    max-width: 95%;
    margin-top: 94px;
    padding: 10px;
  }
}

.terms h1 {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 2em;
  font-weight: 400;
}

@media (width <= 768px) {
  .terms h1 {
    font-size: 1.8em;
  }
}

@media (width <= 506px) {
  .terms h1 {
    font-size: 1.5em;
  }
}

.terms p, .terms ol {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.5;
}

@media (width <= 768px) {
  .terms p, .terms ol {
    font-size: 1em;
  }
}

@media (width <= 506px) {
  .terms p, .terms ol {
    font-size: .9em;
  }
}

.terms ol {
  text-align: left;
  padding-left: 20px;
}

.terms ol li {
  margin-bottom: 15px;
}

.terms .footer {
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  padding: 20px;
}

.terms .footer p {
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

@media (width <= 768px) {
  .terms .footer {
    max-width: 90%;
    margin-top: 20px;
    padding: 15px;
  }
}

@media (width <= 506px) {
  .terms .footer {
    max-width: 95%;
    margin-top: 10px;
    padding: 10px;
  }
}

@font-face {
  font-family: Arlon-SemiBold;
  src: url("Arlon-SemiBold-DEMO.7513292f.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Gineso-NormRegular;
  src: url("Gineso-Norm-Regular.1b989b56.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #06d758;
  justify-content: center;
  align-items: flex-start;
  min-height: 100vh;
  margin: 0;
  display: flex;
  overflow: hidden auto;
}

.privacy-policy {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  min-height: 100vh;
  padding: 20px;
  display: flex;
}

.privacy-policy .nav {
  box-sizing: border-box;
  justify-content: space-between;
  width: 82%;
  margin-top: 29px;
  padding: 20px;
  display: flex;
  position: absolute;
  top: 50px;
}

@media (width <= 768px) {
  .privacy-policy .nav {
    width: 90%;
    padding: 10px;
    top: 10px;
  }
}

@media (width <= 506px) {
  .privacy-policy .nav {
    width: 95%;
    padding: 10px;
    top: 10px;
  }
}

.privacy-policy .xvoucher-logo {
  height: 30px;
}

.privacy-policy .how-guide {
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  display: flex;
}

.privacy-policy .overlap-group {
  background-color: #fff;
  border-radius: 12px;
  width: 120px;
  height: 30px;
  position: relative;
}

.privacy-policy .text-wrapper {
  color: #06d758;
  font-family: Cherry Bomb-Regular, Helvetica;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  position: relative;
  top: 5px;
  left: 10px;
}

.privacy-policy .vector {
  height: 9px;
  margin-left: 13px;
  position: relative;
  top: 5px;
}

.privacy-policy .content {
  overflow-wrap: break-word;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 164px;
  padding: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 14px;
}

@media (width <= 768px) {
  .privacy-policy .content {
    max-width: 90%;
    margin-top: 100px;
    padding: 15px;
  }
}

@media (width <= 506px) {
  .privacy-policy .content {
    max-width: 95%;
    margin-top: 94px;
    padding: 10px;
  }
}

.privacy-policy h1 {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 2em;
  font-weight: 400;
}

@media (width <= 768px) {
  .privacy-policy h1 {
    font-size: 1.8em;
  }
}

@media (width <= 506px) {
  .privacy-policy h1 {
    font-size: 1.5em;
  }
}

.privacy-policy p, .privacy-policy ol {
  margin-bottom: 20px;
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: 1.5;
}

@media (width <= 768px) {
  .privacy-policy p, .privacy-policy ol {
    font-size: 1em;
  }
}

@media (width <= 506px) {
  .privacy-policy p, .privacy-policy ol {
    font-size: .9em;
  }
}

.privacy-policy ol {
  text-align: left;
  padding-left: 20px;
}

.privacy-policy ol li {
  margin-bottom: 15px;
}

.privacy-policy .footer {
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  margin-top: 30px;
  padding: 20px;
}

.privacy-policy .footer p {
  font-family: Gineso-NormRegular, Helvetica;
  font-size: 1.2em;
  font-weight: 400;
  line-height: normal;
}

@media (width <= 768px) {
  .privacy-policy .footer {
    max-width: 90%;
    margin-top: 20px;
    padding: 15px;
  }
}

@media (width <= 506px) {
  .privacy-policy .footer {
    max-width: 95%;
    margin-top: 10px;
    padding: 10px;
  }
}
/*# sourceMappingURL=index.7879f525.css.map */
